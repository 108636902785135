.startpage-kinnan-body,
.startpage-kinnan-body html {
    background-color: rgba(241, 227, 187, 0.6) !important;
}

// #inspire {
//     background: #616365 !important;
// }

// .theme--light.v-application {
//     background: #fff;
//     color: #616365;
// }

// .v-navigation-drawer__content {
//     background: #616365;
// }

// .v-list-item v-list-item--two-line theme--dark {
//     background: #616365;
// }

// .v-navigation-drawer__prepend {
//     background: #616365;
// }

